@use 'src/assets/style/_variables.scss' as var;

@mixin report-request() {
  app-report-request {
    .buttons {
      display: flex;
      flex-display: row;
      gap: 0.5rem;
    }
    .row-body {
      flex-direction: column;
      textarea {
        height: 60vh;
        background-color: beige;
        font-family: monospace;
      }
    }

  } /* app-report-request */
  app-report-request-creator {
    .buttons {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
    mat-expansion-panel {
      border: 1px solid lightgray;
      border-radius: 4px;
      mat-expansion-panel-header {
        border-bottom: 1px solid lightgray;
        &.report-body-header {
          background-color: var.$color-grey-5;
          &:hover,
          &:focus,
          &.mat-expanded {
            background-color: var.$color-grey-4;
          }
        }

      }
    }

    .row-body {
      flex-direction: column;
      max-height: 70vh;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .report-body {
      width: 100%;
      padding: 0.5rem;
      border-radius: 0.5rem;
      &.active {
        background-color: aqua;
      }

      button.report-remove {
        app-config-icon {
          color: white!important;
        }
      }

      .report-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 1.2rem;
        font-weight: bold;
      }
      .report-fields {
        .mat-expansion-panel-body {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 0.5rem;
        }
      }
      mat-panel-title {
        font-weight: bold;
      }
    } /* report-body */

  } /* app-report-request */
}
